"use strict";

if (document.querySelector('.block-news')) {
  var swiper = new Swiper('.block-news .swiper-container', {
    navigation: {
      nextEl: '.swiper-button-next-news',
      prevEl: '.swiper-button-prev-news'
    },
    hideOnClick: true,
    spaceBetween: 30,
    breakpoints: {
      320: {
        slidesPerView: 1
      },
      767: {
        slidesPerView: 2
      },
      991: {
        slidesPerView: 3
      }
    }
  });
}