"use strict";

if (document.querySelector('.content-carousel')) {
  var swiper = new Swiper('.content-carousel .swiper-container', {
    navigation: {
      nextEl: '.swiper-button-next-carousel',
      prevEl: '.swiper-button-prev-carousel'
    },
    spaceBetween: 30,
    breakpoints: {
      320: {
        slidesPerView: 1
      },
      767: {
        slidesPerView: 2
      },
      991: {
        slidesPerView: 3
      }
    }
  });
}