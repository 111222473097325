"use strict";

var WOW_ANIMATE = new WOW({
  mobile: false
}).init();

if (document.querySelector('.phone-mask')) {
  document.querySelectorAll('.phone-mask').forEach(function (item) {
    IMask(item, {
      mask: '+{7} ( 000 ) 000 - 00 - 00'
    });
  });
}

if (document.querySelector('.toogle-open')) {
  document.querySelector('.toogle-open').addEventListener('click', openToogleMenu, false);
}

if (document.querySelector('.toggle-close')) {
  document.querySelector('.toggle-close').addEventListener('click', openToogleMenu, false);
}

if (document.querySelector('.open-drop')) {
  document.querySelectorAll('.open-drop').forEach(function (item) {
    item.addEventListener('click', openDropMenu, false);
  });
}

if (document.querySelector('label.file')) {
  document.querySelectorAll('label.file').forEach(function (item) {
    item.querySelector('input[type="file"]').addEventListener('change', changeFile, false);
  });
}

if (document.querySelector('.accordion-title')) {
  document.querySelectorAll('.accordion-title').forEach(function (item) {
    item.addEventListener('click', openAccordion, false);
  });
}

initEye();

function changeFile(event) {
  var value = event.target.value;
  var labelContainer = event.target.closest('label').querySelector('span');
  if (value) labelContainer.innerHTML = value;else labelContainer.innerHTML = 'Выбрать файл';
}

function openAccordion(event) {
  event.preventDefault(); 

  var currentContainer = event.target.closest('.accordion');

  if (currentContainer.classList.contains('active')) {
    currentContainer.classList.remove('active');
  } else {
    currentContainer.classList.add('active');
  }
}

function openToogleMenu(event) {
  event.preventDefault();
  var topMenu = document.querySelector('.top-nav');
  var html = document.querySelector('html');

  if (topMenu) {
    if (topMenu.classList.contains('menu-toogle-open')) {
      html.classList.remove('modal');
      topMenu.classList.remove('menu-toogle-open');
      setTimeout(function () {
        topMenu.classList.remove('menu-toogle-load');
      }, 100);
    } else {
      topMenu.classList.add('menu-toogle-load');
      setTimeout(function () {
        topMenu.classList.add('menu-toogle-open');
      }, 100);
      html.classList.add('modal');
    }
  }
}

function openDropMenu(event) {
  event.preventDefault();
  var target = event.target;
  var menu = target.closest('li').querySelector('ul');

  if (menu.classList.contains('active')) {
    menu.classList.remove('active');
    menu.classList.remove('drop-menu-open');
    target.classList.remove('down');
    target.classList.add('up');
  } else {
    menu.classList.add('active');
    menu.classList.add('drop-menu-open');
    target.classList.remove('up');
    target.classList.add('down');
  }
}

function setCookie(name, value) {
  var options = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  var updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (var optionKey in options) {
    updatedCookie += "; " + optionKey;
    var optionValue = options[optionKey];

    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

function getCookie(name) {
  var matches = document.cookie.match(new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function deleteCookie(name) {
  setCookie(name, "", {
    'max-age': -1
  });
}

function offEye() {
  deleteCookie('cookie_bg');
  deleteCookie('cookie_font');
  deleteCookie('cookie_letter');
  deleteCookie('cookie_size');
  deleteCookie('cookie_img');
  window.location.reload();
}

function onEye() {
  document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=1210');
  document.querySelector('body').classList.add('eye-on');
  document.querySelector('html').classList.remove('modal');
  document.querySelector('.panel-eye').classList.remove('d-none');
  changeBgEyeClass('style_dr', 'style_bl', 'style_wh', 'btn-bg-black', 'btn-bg-blue', 'btn-bg-white');
  changeFontEyeClass('font_a', 'font_t', 'btn-fontfamily-times', 'btn-fontfamily-arial');
  changeLetterEyeClass('letter-md', 'letter-lg', 'letter-default', 'btn-letter-md', 'btn-letter-lg', 'btn-letter-default');
  changeSizeEyeClass('fontsize-md', 'fontsize-lg', 'fontsize-sm', 'btn-fontsize-md', 'btn-fontsize-lg', 'btn-fontsize-sm');
  changeImgEyeClass('image-off', 'image-on', true);
  setCookie('cookie_letter', 'letter-default', {
    secure: true,
    'max-age': 3600 * 24 * 10
  });
  setCookie('cookie_bg', 'style_wh', {
    secure: true,
    'max-age': 3600 * 24 * 10
  });
  setCookie('cookie_font', 'font_a', {
    secure: true,
    'max-age': 3600 * 24 * 10
  });
  setCookie('cookie_size', 'fontsize-sm', {
    secure: true,
    'max-age': 3600 * 24 * 10
  });
  setCookie('cookie_img', 'on', {
    secure: true,
    'max-age': 3600 * 24 * 10
  });
}

function changeBgEyeClass(className1, className2, className3, className4, className5, className6) {
  document.querySelector('body').classList.remove(className1);
  document.querySelector('body').classList.remove(className2);
  document.querySelector('body').classList.add(className3);
  document.querySelectorAll('.' + className4 + ', .' + className5).forEach(function (item) {
    item.classList.remove('active');
  });
  document.querySelectorAll('.' + className6).forEach(function (item) {
    item.classList.add('active');
  });
}

function changeLetterEyeClass(className1, className2, className3, className4, className5, className6) {
  document.querySelector('body').classList.remove(className1);
  document.querySelector('body').classList.remove(className2);
  document.querySelector('body').classList.add(className3);
  document.querySelectorAll('.' + className4 + ', .' + className5).forEach(function (item) {
    item.classList.remove('active');
  });
  document.querySelectorAll('.' + className6).forEach(function (item) {
    item.classList.add('active');
  });
}

function changeFontEyeClass(className1, className2, className3, className4) {
  document.querySelector('body').classList.add(className1);
  document.querySelector('body').classList.remove(className2);
  document.querySelectorAll('.' + className3).forEach(function (item) {
    item.classList.remove('active');
  });
  document.querySelectorAll('.' + className4).forEach(function (item) {
    item.classList.add('active');
  });
}

function changeSizeEyeClass(className1, className2, className3, className4, className5, className6) {
  document.querySelector('body').classList.remove(className1);
  document.querySelector('body').classList.remove(className2);
  document.querySelector('body').classList.add(className3);
  document.querySelectorAll('.' + className4 + ', .' + className5).forEach(function (item) {
    item.classList.remove('active');
  });
  document.querySelectorAll('.' + className6).forEach(function (item) {
    item.classList.add('active');
  });
}

function changeImgEyeClass(className1, className2, show) {
  document.querySelector('body').classList.add('eye-on');
  document.querySelectorAll('.' + className1).forEach(function (item) {
    item.classList.remove('active');
  });
  document.querySelectorAll('.' + className2).forEach(function (item) {
    item.classList.add('active');
  });

  if (show) {
    document.querySelectorAll('img, svg, .img-element').forEach(function (item) {
      item.classList.remove('img-hide');
    });
    document.querySelectorAll('.bg-element').forEach(function (item) {
      item.classList.remove('bg-hide');
    });
  } else {
    document.querySelectorAll('img, svg, .img-element').forEach(function (item) {
      item.classList.add('img-hide');
    });
    document.querySelectorAll('.bg-element').forEach(function (item) {
      item.classList.add('bg-hide');
    });
  }
} 


function initEye() {
  if (document.querySelector('.panel-eye')) {
    var cookie_bg = getCookie('cookie_bg');

    if (cookie_bg) {
      document.querySelector('body').classList.add('eye-on');
      document.querySelector('html').classList.remove('modal');
      document.querySelector('.panel-eye').classList.remove('d-none');
      document.querySelector('meta[name="viewport"]').setAttribute('content', 'width=1210');
    }

    document.querySelectorAll('.menu-settings').forEach(function (item) {
      item.addEventListener('click', function (event) {
        event.preventDefault();

        if (document.querySelector('.settings').classList.contains('d-none')) {
          document.querySelector('.settings').classList.remove('d-none');
        } else {
          document.querySelector('.settings').classList.add('d-none');
        }
      }, false);
    });
    document.querySelectorAll('.btn-panel-eye').forEach(function (item) {
      item.addEventListener('click', function (event) {
        event.preventDefault();

        if (document.querySelector('.panel-eye').classList.contains('d-none')) {
          onEye();
          item.innerHTML = 'Обычная версия';
        } else {
          item.innerHTML = 'Версия для слабовидящих';
          offEye();
        }
      }, false);

      if (cookie_bg) {
        item.innerHTML = 'Обычная версия';
      } else {
        item.innerHTML = 'Версия для слабовидящих';
      }
    });

    if (cookie_bg == 'style_wh') {
      changeBgEyeClass('style_dr', 'style_bl', 'style_wh', 'btn-bg-black', 'btn-bg-blue', 'btn-bg-white');
    }

    if (cookie_bg == 'style_dr') {
      changeBgEyeClass('style_wh', 'style_bl', 'style_dr', 'btn-bg-white', 'btn-bg-blue', 'btn-bg-black');
    }

    if (cookie_bg == 'style_bl') {
      changeBgEyeClass('style_wh', 'style_dr', 'style_bl', 'btn-bg-white', 'btn-bg-black', 'btn-bg-blue');
    }

    document.querySelectorAll('.btn-bg-white').forEach(function (item) {
      item.addEventListener('click', function (event) {
        event.preventDefault();
        changeBgEyeClass('style_dr', 'style_bl', 'style_wh', 'btn-bg-black', 'btn-bg-blue', 'btn-bg-white');
        setCookie('cookie_bg', 'style_wh', {
          secure: true,
          'max-age': 3600 * 24 * 10
        });
      }, false);
    });
    document.querySelectorAll('.btn-bg-black').forEach(function (item) {
      item.addEventListener('click', function (event) {
        event.preventDefault();
        changeBgEyeClass('style_wh', 'style_bl', 'style_dr', 'btn-bg-white', 'btn-bg-blue', 'btn-bg-black');
        setCookie('cookie_bg', 'style_dr', {
          secure: true,
          'max-age': 3600 * 24 * 10
        });
      }, false);
    });
    document.querySelectorAll('.btn-bg-blue').forEach(function (item) {
      item.addEventListener('click', function (event) {
        event.preventDefault();
        changeBgEyeClass('style_wh', 'style_dr', 'style_bl', 'btn-bg-white', 'btn-bg-black', 'btn-bg-blue');
        setCookie('cookie_bg', 'style_bl', {
          secure: true,
          'max-age': 3600 * 24 * 10
        });
      }, false);
    });
    var cookie_font = getCookie('cookie_font');

    if (cookie_font == 'font_a') {
      changeFontEyeClass('font_a', 'font_t', 'btn-fontfamily-times', 'btn-fontfamily-arial');
    }

    if (cookie_font == 'font_t') {
      changeFontEyeClass('font_t', 'font_a', 'btn-fontfamily-arial', 'btn-fontfamily-times');
    }

    document.querySelectorAll('.btn-fontfamily-arial').forEach(function (item) {
      item.addEventListener('click', function (event) {
        event.preventDefault();
        changeFontEyeClass('font_a', 'font_t', 'btn-fontfamily-times', 'btn-fontfamily-arial');
        setCookie('cookie_font', 'font_a', {
          secure: true,
          'max-age': 3600 * 24 * 10
        });
      }, false);
    });
    document.querySelectorAll('.btn-fontfamily-times').forEach(function (item) {
      item.addEventListener('click', function (event) {
        event.preventDefault();
        changeFontEyeClass('font_t', 'font_a', 'btn-fontfamily-arial', 'btn-fontfamily-times');
        setCookie('cookie_font', 'font_t', {
          secure: true,
          'max-age': 3600 * 24 * 10
        });
      }, false);
    });
    var cookie_letter = getCookie('cookie_letter');

    if (cookie_letter == 'letter-default') {
      changeLetterEyeClass('letter-md', 'letter-lg', 'letter-default', 'btn-letter-md', 'btn-letter-lg', 'btn-letter-default');
    }

    if (cookie_letter == 'letter-md') {
      changeLetterEyeClass('letter-default', 'letter-lg', 'letter-md', 'btn-letter-default', 'btn-letter-lg', 'btn-letter-md');
    }

    if (cookie_letter == 'letter-lg') {
      changeLetterEyeClass('letter-default', 'letter-md', 'letter-lg', 'btn-letter-default', 'btn-letter-md', 'btn-letter-lg');
    }

    document.querySelectorAll('.btn-letter-default').forEach(function (item) {
      item.addEventListener('click', function (event) {
        event.preventDefault();
        changeLetterEyeClass('letter-md', 'letter-lg', 'letter-default', 'btn-letter-md', 'btn-letter-lg', 'btn-letter-default');
        setCookie('cookie_letter', 'letter-default', {
          secure: true,
          'max-age': 3600 * 24 * 10
        });
      }, false);
    });
    document.querySelectorAll('.btn-letter-md').forEach(function (item) {
      item.addEventListener('click', function (event) {
        event.preventDefault();
        changeLetterEyeClass('letter-default', 'letter-lg', 'letter-md', 'btn-letter-default', 'btn-letter-lg', 'btn-letter-md');
        setCookie('cookie_letter', 'letter-md', {
          secure: true,
          'max-age': 3600 * 24 * 10
        });
      }, false);
    });
    document.querySelectorAll('.btn-letter-lg').forEach(function (item) {
      item.addEventListener('click', function (event) {
        event.preventDefault();
        changeLetterEyeClass('letter-default', 'letter-md', 'letter-lg', 'btn-letter-default', 'btn-letter-md', 'btn-letter-lg');
        setCookie('cookie_letter', 'letter-lg', {
          secure: true,
          'max-age': 3600 * 24 * 10
        });
      }, false);
    });
    var cookie_size = getCookie('cookie_size');

    if (cookie_size == 'fontsize-sm') {
      changeSizeEyeClass('fontsize-md', 'fontsize-lg', 'fontsize-sm', 'btn-fontsize-md', 'btn-fontsize-lg', 'btn-fontsize-sm');
    }

    if (cookie_size == 'fontsize-md') {
      changeSizeEyeClass('fontsize-sm', 'fontsize-lg', 'fontsize-md', 'btn-fontsize-sm', 'btn-fontsize-lg', 'btn-fontsize-md');
    }

    if (cookie_size == 'fontsize-lg') {
      changeSizeEyeClass('fontsize-sm', 'fontsize-md', 'fontsize-lg', 'btn-fontsize-sm', 'btn-fontsize-md', 'btn-fontsize-lg');
    }

    document.querySelectorAll('.btn-fontsize-sm').forEach(function (item) {
      item.addEventListener('click', function (event) {
        event.preventDefault();
        changeSizeEyeClass('fontsize-md', 'fontsize-lg', 'fontsize-sm', 'btn-fontsize-md', 'btn-fontsize-lg', 'btn-fontsize-sm');
        setCookie('cookie_size', 'fontsize-sm', {
          secure: true,
          'max-age': 3600 * 24 * 10
        });
      }, false);
    });
    document.querySelectorAll('.btn-fontsize-md').forEach(function (item) {
      item.addEventListener('click', function (event) {
        event.preventDefault();
        changeSizeEyeClass('fontsize-sm', 'fontsize-lg', 'fontsize-md', 'btn-fontsize-sm', 'btn-fontsize-lg', 'btn-fontsize-md');
        setCookie('cookie_size', 'fontsize-md', {
          secure: true,
          'max-age': 3600 * 24 * 10
        });
      }, false);
    });
    document.querySelectorAll('.btn-fontsize-lg').forEach(function (item) {
      item.addEventListener('click', function (event) {
        event.preventDefault();
        changeSizeEyeClass('fontsize-sm', 'fontsize-md', 'fontsize-lg', 'btn-fontsize-sm', 'btn-fontsize-md', 'btn-fontsize-lg');
        setCookie('cookie_size', 'fontsize-lg', {
          secure: true,
          'max-age': 3600 * 24 * 10
        });
      }, false);
    });
    var cookie_img = getCookie('cookie_img');

    if (cookie_img == 'on') {
      changeImgEyeClass('image-off', 'image-on', true);
    }

    if (cookie_img == 'off') {
      changeImgEyeClass('image-on', 'image-off', false);
    }

    document.querySelectorAll('.image-on').forEach(function (item) {
      item.addEventListener('click', function (event) {
        event.preventDefault();
        changeImgEyeClass('image-off', 'image-on', true);
        setCookie('cookie_img', 'on', {
          secure: true,
          'max-age': 3600 * 24 * 10
        });
        window.dispatchEvent(new Event('resize'));
      }, false);
    });
    document.querySelectorAll('.image-off').forEach(function (item) {
      item.addEventListener('click', function (event) {
        event.preventDefault();
        changeImgEyeClass('image-on', 'image-off', false);
        setCookie('cookie_img', 'off', {
          secure: true,
          'max-age': 3600 * 24 * 10
        });
      }, false);
    });
  }
} 