"use strict";

if (document.querySelector('.block-review')) {
  var swiper = new Swiper('.block-review .swiper-container', {
    navigation: {
      nextEl: '.swiper-button-next-review',
      prevEl: '.swiper-button-prev-review'
    },
    slidesPerView: 'auto',
    slidesPerGroup: 1,
    initialSlide: 2,
    spaceBetween: 30,
    lazy: {
      loadPrevNext: true
    },
    centeredSlides: true,
    grabCursor: true,
    loop: true
  });
}