"use strict";

if (document.querySelector('.block-quality')) {
  var swiper = new Swiper('.block-quality .swiper-container', {
    navigation: false,
    spaceBetween: 30,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
      dynamicBullets: true,
      dynamicMainBullets: 4
    },
    breakpoints: {
      1199: {
        slidesPerView: 3,
        slidesPerColumn: 2
      },
      320: {
        slidesPerView: 2,
        slidesPerColumn: 2
      }
    }
  });
}